
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  name: 'modal',
  components: {
    SlideYUpTransition,
  },
  props: {
    show: Boolean,
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
      validator(value: string) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|"") ',
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration',
    },
    closeOnBgClick: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    onBackgroundAreaClicked() {
      if (this.closeOnBgClick) {
        this.closeModal();
      }
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        const documentClasses = document.body.classList;
        if (val) {
          documentClasses.add('modal-open');
        } else {
          documentClasses.remove('modal-open');
        }
      },
    },
  },
};
