// throttleとかdebounceが実装してあってもいいよね的な
type GatedFuncResult<R> = [false, undefined] | [true, R];

interface GatedFuncGenerator {
  makeAsyncFuncGated: <A extends any[] = any[], R extends any = any>(
    asyncFunc: (...args: A) => Promise<R>,
    opts?: { tag?: string; maxCalls?: number },
  ) => (...args: A) => Promise<GatedFuncResult<R>>;
}

export function getGatedFuncGenerator(): GatedFuncGenerator {
  const timesCalledByTag: Record<string, number> = {};
  return {
    makeAsyncFuncGated<A extends any[] = any[], R extends any = any>(
      asyncFunc: (...args: A) => Promise<R>,
      opts: { tag?: string; maxCalls?: number } = {},
    ): (...args: A) => Promise<GatedFuncResult<R>> {
      const tag = opts.tag ?? 'isLoading';
      const maxCalls = opts.maxCalls ?? -1;
      return async (...args: A) => {
        const timesCalled = timesCalledByTag[tag] ?? 0;
        if (maxCalls !== -1 && timesCalled >= maxCalls) {
          return [false, undefined];
        }
        timesCalledByTag[tag] = timesCalled + 1;

        let succeeded = false;
        let funcResult: R;
        try {
          funcResult = await asyncFunc(...args);
          succeeded = true;
        } finally {
          // nothing to do
        }

        return succeeded ? [true, funcResult] : [false, undefined];
      };
    },
  };
}
