import { render, staticRenderFns } from "./MetricsTableComponent.vue?vue&type=template&id=0f9beda7&scoped=true&"
import script from "./MetricsTableComponent.vue?vue&type=script&setup=true&lang=ts&"
export * from "./MetricsTableComponent.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./MetricsTableComponent.vue?vue&type=style&index=0&id=0f9beda7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f9beda7",
  null
  
)

export default component.exports