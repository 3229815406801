
import Vue, { computed, defineComponent, getCurrentInstance, onMounted, reactive, ref } from 'vue';
import reportApi from 'src/apis/masters/report';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserRefreshAndMasters } from 'src/hooks/masterHook';
import SortButton from 'src/components/UIComponents/Sorter/SortButton.vue';
import { useSortContext } from 'src/components/UIComponents/Sorter/SortContext.vue';
import { SortSpec } from 'src/components/UIComponents/Sorter/types';
import { ERROR_GROUP_SYSTEM, ERROR_REASON_IN_USE, ErrorGroup, TimeSpan } from 'src/consts';
import ReportSearchPanel, { ReportSearchState } from 'src/components/ReportSearchPanel/index.vue';
import { constructEmptyReport, Report } from 'src/models/new/report';
import { compareFuncTimeSpan, timeSpanToLocalWord } from 'src/business/timeSpan';
import SaveFormModal from 'src/views/Dashboard/Settings/Reports/vueComponents/SaveFormModal/index.vue';
import { usePaginationContainer } from 'src/components/UIComponents/PaginationContainer.vue';
import { OrderElement } from 'src/models/api/shared/orderElement';
import { isLoginUserAuthorizedToEditReport } from 'src/models/new/User/loginUser';
import { useLoginUser } from 'src/composables/useLoginUser';
import TooltipButton from 'src/components/UIComponents/Tooltip/TooltipButton.vue';
import { TooltipMenuSettings } from 'src/components/UIComponents/Tooltip/types';
import ReportThemeColorRoundPoint from 'src/components/ReportThemeColorRoundPoint.vue';

const ITEM_PER_PAGE = 50;

interface State {
  pageName: string | null;
  userId: number | null;
  hasReportGtManagerRole: boolean;
  reportItems: Report[];
  showSaveModal: boolean;
  deleteCandidate: Report | null;
  showDeleteModal: boolean;
  dataLoadState: { availableTotal: number; loadedSlices: number };
  isSortable: boolean;
  hasList: boolean;
  hasPageAccessRole: boolean;
  targetReport: Report | null;
}

function setupState(root: Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    reportItems: [],
    hasReportGtManagerRole: computed(() => root.$store.getters['user/hasReportGtManagerRole']),
    sortManager: null,
    budgetGroups: [],
    showSaveModal: false,
    deleteCandidate: null,
    showDeleteModal: false,
    hasList: computed(() => state.reportItems.length > 0),
    dataLoadState: { availableTotal: 0, loadedSlices: 0 },
    isSortable: true,
    hasPageAccessRole: true,
    targetReport: null,
  });
  return state;
}

export default defineComponent({
  components: {
    ReportSearchPanel,
    SortButton,
    SortContext: useSortContext<Report>(),
    SaveFormModal,
    PaginationContainer: usePaginationContainer<Report>(),
    TooltipButton,
    ReportThemeColorRoundPoint,
  },
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, 'レポート設定');
    const state = setupState(root);
    const searchParams = ref<ReportSearchState>({
      name: null,
      timeSpan: null,
      includeDisabled: false,
    });

    const { loginUserRef, refreshLoginUser } = useLoginUser();

    // ソート定義(compareFuncとkeyの組み合わせ)
    const sortSpecs = ref<SortSpec[]>([]);
    function getDefaultSortSpecs(): SortSpec[] {
      return [{ key: 'name' }, { key: 'timeSpan', compareFunc: compareFuncTimeSpan }];
    }
    function resetToDefaultSortOrder(): void {
      sortSpecs.value = getDefaultSortSpecs();
    }
    resetToDefaultSortOrder();

    function createReportTooltipMenus(report: Report): TooltipMenuSettings[] {
      return [
        {
          text: '基本設定',
          iconClasses: 'fal fa-pen',
          onClick: () => openSaveModal(report),
        },
        {
          text: '詳細設定',
          iconClasses: 'fal fa-cog',
          link: {
            to: {
              name: 'SettingsReportsEdit',
              params: {
                reportId: String(report.id),
                name: searchParams.value.name || '',
                time_span: searchParams.value.timeSpan ? String(searchParams.value.timeSpan) : '',
                include_disabled: searchParams.value.includeDisabled ? '1' : '',
              },
            },
          },
        },
      ];
    }

    function getApiOrder(): Array<OrderElement<'name' | 'time_span'>> {
      return [
        {
          key: 'name',
          direction: 'asc',
        },
        {
          key: 'time_span',
          direction: 'asc',
        },
      ];
    }

    async function loadReports(): Promise<void> {
      try {
        state.dataLoadState.loadedSlices = 1;
        const response = await reportApi.index({
          name: searchParams.value.name,
          time_span: searchParams.value.timeSpan,
          include_disabled: searchParams.value.includeDisabled,
          page: state.dataLoadState.loadedSlices,
          order: getApiOrder(),
        });
        state.reportItems = response.result;
        state.dataLoadState.availableTotal = response.pagination.total;
        state.isSortable = true;
        // 追加ロードが必要な場合はソート不可としソート条件をデフォルトに変更
        if (state.dataLoadState.availableTotal > response.pagination.limitValue) {
          state.isSortable = false;
          resetToDefaultSortOrder();
        }
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    }

    async function loadReportsNextSlice(): Promise<void> {
      try {
        state.dataLoadState.loadedSlices += 1;
        const response = await reportApi.index({
          name: searchParams.value.name,
          time_span: searchParams.value.timeSpan,
          include_disabled: searchParams.value.includeDisabled,
          page: state.dataLoadState.loadedSlices,
          order: getApiOrder(),
        });
        state.reportItems = [...state.reportItems, ...response.result];
        state.dataLoadState.availableTotal = response.pagination.total;
      } catch (err: any) {
        const errStatus = err.response.status;
        if (errStatus === 403) {
          state.hasPageAccessRole = false;
        }
      }
    }

    async function onSearch(params: ReportSearchState) {
      searchParams.value = params;
      await loadReports();
    }

    function openSaveModal(report?: Report): void {
      state.targetReport = structuredClone(report) || constructEmptyReport();
      state.showSaveModal = true;
    }

    function closeSaveModal(): void {
      state.showSaveModal = false;
    }

    const onUpdated = (message: string): void => {
      loadReports();
      closeSaveModal();
      notifySuccess1(root, message);
    };

    // TODO: アプリケーションを通じて要統一、reportValue/Detailとエラーフォーマットが微妙に異なる
    const reportError = async (errorGroup: ErrorGroup, message: string, error: any, errorId: string) => {
      const formattedMessage =
        errorGroup === ERROR_GROUP_SYSTEM
          ? `(ERR: ${state.pageName} ${errorId ? ` ${errorId}` : ''}, user_id:${state.userId})`
          : message;

      notifyError1(root, formattedMessage, error);
    };

    function openDeleteModal(item: Report): void {
      state.deleteCandidate = item;
      state.showDeleteModal = true;
    }

    function closeDeleteModal(): void {
      state.deleteCandidate = null;
      state.showDeleteModal = false;
    }

    async function deleteItem(): Promise<void> {
      if (!state.deleteCandidate) {
        return;
      }

      try {
        await reportApi.delete(state.deleteCandidate.id);
        await loadReports();
        notifySuccess1(root, `${state.deleteCandidate.name}を削除しました`);
        closeDeleteModal();
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if ([403, 404].includes(errStatus)) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
          await refreshLoginUser();
        } else if (errStatus === 400 && errRes.reason === ERROR_REASON_IN_USE) {
          const msg = 'すでに使われているレポートです。削除できません。無効化をおすすめします。';
          notifyError1(root, msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00003';
          const msg =
            'レポートの削除に失敗しました。管理者に連絡してください。' +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    }

    const isValidNameToCopy = (report: Report): boolean => {
      // レポートのnameは50文字までとされている
      // https://github.com/kurando-inc/logiscope-ap/blob/2107cbea592ea6ebe5a6922a207789ac2e7f21fd/src/assets/src/views/Dashboard/Settings/Reports/Form/index.vue#L198
      // TODO: 現状これらの文字数制限に関してはフロントエンドの各Vueコンポーネントでのみ定義しており
      // バックエンド、フロントエンド双方のモデルのロジックに制約を盛り込むように変更すべき
      return report.name.length <= 50 - '_コピー_xx'.length;
    };

    const copyItem = async (report: Report): Promise<void> => {
      if (!isValidNameToCopy(report)) {
        notifyError1(root, 'コピー元のレポート名が長すぎるため、コピーできません。');
        return;
      }

      try {
        await reportApi.copy(report.id);
        await loadReports();
        notifySuccess1(root, `${report.name}のコピーを作成しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        if ([403, 404].includes(errStatus)) {
          notifyError1(root, 'アクセスする権限がありません。管理者にお問合せください。', { err });
          await refreshLoginUser();
        } else {
          const errId = 'ERR00004';
          const msg =
            'レポートのコピーに失敗しました。管理者に連絡してください。' +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    };

    // 初期設定パラメータ
    function setInitSearchParams(): void {
      let name: string | null = null;
      let timeSpan: TimeSpan | null = null;
      let includeDisabled: boolean = false;

      if (root.$route.params.time_span) {
        timeSpan = root.$route.params.time_span as TimeSpan;
        root.$route.params.time_span = '';
      }
      if (root.$route.params.name) {
        name = root.$route.params.name;
        root.$route.params.name = '';
      }
      if (root.$route.params.include_disabled) {
        includeDisabled = !!root.$route.params.include_disabled;
        root.$route.params.include_disabled = '';
      }
      searchParams.value = {
        ...searchParams.value,
        name: name,
        timeSpan: timeSpan,
        includeDisabled: includeDisabled,
      };
    }

    onMounted(async () => {
      // ログインユーザー情報をAPIで再取得
      await ensureUserRefreshAndMasters(root);

      setInitSearchParams();
      await loadReports();

      // レポートトランから新規作成ボタンを押して遷移してきた場合の処理
      // クエリパラメータで判定しているが、もっと良いやり方があるかもしれない
      if (root.$route.query.fromRoute === 'ReportValues') {
        openSaveModal();
        // 作成モーダルを開いたらクエリパラメータ削除
        await root.$router.push({ query: {} });
      }
    });

    return {
      state,
      openSaveModal,
      closeSaveModal,
      onUpdated,
      reportError,
      openDeleteModal,
      closeDeleteModal,
      deleteItem,
      copyItem,
      onSearch,
      searchParams,
      sortSpecs,
      timeSpanToLocalWord,
      loadReportsNextSlice,
      ITEM_PER_PAGE,
      isLoginUserAuthorizedToEditReport,
      createReportTooltipMenus,
      loginUserRef,
    };
  },
});
