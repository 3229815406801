import { computed, getCurrentInstance, onUnmounted, ref, unref, watch } from 'vue';
import { useValidator } from 'src/composables/useValidator';
import {
  LOGI_SYSTEM_DATA_METRICS_MAX_QUERY_PARAMETERS,
  LogiSystemDataMetricsQueryParameter,
  LogiSystemDataQueryParameterValue,
} from 'src/models/new/Metrics/BasicMetrics/logiSystemDataMetrics';
import { MapObject } from 'vee-validate';
import { useDataSources } from 'src/composables/asyncResources/useDataSources';
import { RecordId } from 'src/util/recordId';

type DataSourceParameterInput = {
  parameterId: number | null;
  values: LogiSystemDataQueryParameterValue[];
};

const defaultDataSourceParameterInput = {
  parameterId: null,
  values: [],
};

export const useDataSourceParameterInput = () => {
  const inputs = ref<DataSourceParameterInput[]>([]);

  const vue = getCurrentInstance()!.proxy;

  const initialize = (logiSystemDataMetricsQueryParameters: LogiSystemDataMetricsQueryParameter[]) => {
    const newInputs: DataSourceParameterInput[] = [];
    logiSystemDataMetricsQueryParameters.forEach((parameter) => {
      const index = newInputs.findIndex((input) => input.parameterId === parameter.parameterId);
      if (index !== -1) {
        newInputs[index]!.values.push(parameter.value);
      } else {
        newInputs.push({ parameterId: parameter.parameterId, values: [parameter.value] });
      }
    });
    inputs.value = newInputs;
  };

  const clear = () => {
    inputs.value = [structuredClone(defaultDataSourceParameterInput)];
  };

  const pop = () => {
    inputs.value.pop();
  };

  const push = () => {
    inputs.value.push(structuredClone(defaultDataSourceParameterInput));
  };

  const reflect = (): LogiSystemDataMetricsQueryParameter[] => {
    const dataSourceParameters: LogiSystemDataMetricsQueryParameter[] = [];
    inputs.value.forEach((dataSourceParameterInput) => {
      const dataSourceParametersForInput = dataSourceParameterInput.values.map((value) => {
        return {
          parameterId: dataSourceParameterInput.parameterId!,
          value: value,
        };
      });
      dataSourceParameters.push(...dataSourceParametersForInput);
    });

    return dataSourceParameters;
  };

  const fieldNames = computed(() => {
    return {
      eachFields: inputs.value.map((_input, index) => `dataSourceParameterValue_${index}`),
      combined: 'dataSourceParameterValues',
    };
  });

  const cleanUpValidationErrors = () => {
    vue.$validator.errors.remove(fieldNames.value.combined);
    fieldNames.value.eachFields.forEach((fieldName) => {
      vue.$validator.errors.remove(fieldName);
    });
  };

  const removeFieldErrorByIndex = (fieldIndex: number) => {
    // FIXME: noUncheckedIndexedAccess有効化に伴う暫定対応
    // eachFields[fieldIndex] => eachFields[fieldIndex]!
    vue.$validator.errors.remove(fieldNames.value.eachFields[fieldIndex]!);
  };

  // FIXME: vee_validate.tsで共通化すべき処理があるので、vee_validate.tsを修正・拡張するのが良い
  const ensureValidation = async (): Promise<boolean> => {
    let isValid = true;

    cleanUpValidationErrors();

    // 取得条件のパラメータidが存在する場合は、そのパラメータの値を必須とする
    inputs.value.forEach(async (input, index) => {
      if (input.parameterId && input.values.length === 0) {
        vue.$validator.errors.add({
          // FIXME: noUncheckedIndexedAccess有効化に伴う暫定対応
          // eachFields[fieldIndex] => eachFields[fieldIndex]!
          field: fieldNames.value.eachFields[index]!,
          rule: 'required',
          // msgの値を使うことはないがtruthyであってほしいのでダミー値を入れておく
          msg: 'error',
        });
        isValid = false;
      }
    });
    const valueCount = inputs.value.reduce((count, input) => count + input.values.length, 0);
    if (valueCount > LOGI_SYSTEM_DATA_METRICS_MAX_QUERY_PARAMETERS) {
      vue.$validator.errors.add({
        field: fieldNames.value.combined,
        rule: 'max',
        // msgの値を使うことはないがtruthyであってほしいのでダミー値を入れておく
        msg: 'error',
      });
      isValid = false;
    }
    return isValid;
  };

  const { validationResult, setValidation } = useValidator();
  setValidation(
    computed(() => {
      // validationResultに監視するエラーを登録するだけなのでvalidationの中身はダミー値true
      return fieldNames.value.eachFields.reduce<MapObject>(
        (validations, eachField) => {
          validations[eachField] = true;
          return validations;
        },
        { [fieldNames.value.combined]: true },
      );
    }),
  );

  onUnmounted(() => {
    cleanUpValidationErrors();
  });

  const { dataSourceParametersRef } = useDataSources();

  const selectedKeyId = ref<RecordId | null>(null);
  const selectKeyId = (dataSourceKeyId: number | null) => {
    selectedKeyId.value = dataSourceKeyId;
  };

  const getDataSourceParameterOptions = (fieldIndex: number) => {
    const keyId = unref(selectedKeyId);
    if (keyId === null) {
      return [];
    }

    return dataSourceParametersRef.value
      .filter((dataSourceParameter) => {
        const reservedParameterIds = inputs.value
          .filter((_, index) => index !== fieldIndex)
          .map((el) => el.parameterId);

        return dataSourceParameter.dataSourceKeyId === keyId && !reservedParameterIds.includes(dataSourceParameter.id);
      })
      .map((dataSourceParameter) => {
        return {
          value: dataSourceParameter.id,
          label: dataSourceParameter.disp_name,
        };
      });
  };

  const onSelectDataSourceParameter = (formIndex: number) => {
    // // dataSourceParameterValuesの選択状態を解除
    const input = inputs.value[formIndex];
    if (input !== undefined) {
      input.values = [];
    }

    // 操作によって上限オーバーなど、操作対象以外のフォームで発生するエラーを再評価する
    cleanUpValidationErrors();
    ensureValidation();
    // 再評価した上で操作対象のエラーについてはクリアする
    removeFieldErrorByIndex(formIndex);
  };

  return {
    inputs,
    fieldNames,
    selectedKeyId,
    validationResult,
    initialize,
    clear,
    pop,
    push,
    reflect,
    selectKeyId,
    ensureValidation,
    onSelectDataSourceParameter,
    getDataSourceParameterOptions,
  };
};
